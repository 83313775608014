@use "variables" as *;

.spinnerWrapper {
  position: absolute;
  top: 50vh;
  left: calc(50% - 44px);
}

.headerIcon {
  height: 24px !important;
  width: 24px !important;
  margin: 0 $spacing-1 3px 0;
}

p {
  margin: 0;
}

.listWrapperItem {
  cursor: default;
}
