import {
  ErrorTypes,
  ErrorPage,
  commonPathnames as pathnames,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Settings from "../settings/Settings";

export const Page404 = () => <ErrorPage type={ErrorTypes.e404} />;

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path={pathnames.userHome} element={<Settings />} />
      <Route path="/myaccount" element={<Navigate to="/" replace />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Router;
