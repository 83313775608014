import { Switch, Input } from "@flixbus/honeycomb-react";
import { Autocomplete, debounce, timezones } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { BookingReportsPreference, PreferenceInput } from "../../../shared/types/schema";
import * as css from "./BookingReportItem.scss";

type Props = {
  preference: BookingReportsPreference;
  onChange: (values: PreferenceInput["bookingReports"]) => void;
};

const BookingReportItem: React.FC<Props> = ({ preference, onChange }) => {
  const { formatMessage } = useIntl();

  const handleTimeInputChange = debounce(
    (value: string) =>
      onChange({
        active: preference.active,
        time: value,
        timezone: preference.timezone,
      }),
    process.env.NODE_ENV === "test" ? 0 : 500
  );

  return (
    <div className={css.listItem}>
      <div>
        <p>
          <FormattedMessage id="settings.dailyBookingReportNotification.header" />
        </p>
        <p className={css.info}>
          <FormattedMessage id="settings.dailyBookingReportNotification.subheader" />
        </p>
        <div data-testid="select-wrapper" className={css.setupWrapper}>
          <Input
            id="booking-report-time-input"
            aria-label="booking-report-time-input"
            data-testid="booking-report-time-input"
            type="time"
            label={formatMessage({ id: "time.input.label" })}
            info={formatMessage({ id: "time.input.info" })}
            defaultValue={preference.time}
            onChange={(event) => {
              handleTimeInputChange(event.target.value);
            }}
          />
          <div className={css.timezoneWrapper}>
            <Autocomplete
              label={formatMessage({ id: "timezone.select.label" })}
              options={timezones()}
              startsOnInputValueLength={0}
              initialValue={{ value: preference.timezone, label: preference.timezone }}
              onValueSelected={(selectedValue) => {
                onChange({
                  active: preference.active,
                  time: preference.time,
                  timezone: selectedValue.value,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className={css.switchWrapper}>
        <Switch
          id="booking-report-switch"
          aria-label="booking-report"
          defaultChecked={preference.active}
          onChange={({ currentTarget }) => {
            onChange({
              active: currentTarget.checked,
              time: preference.time,
              timezone: preference.timezone,
            });
          }}
          extraClasses={css.switch}
        />
      </div>
    </div>
  );
};

export default BookingReportItem;
