@use "variables" as *;
@use "../../shared/styles/ListItemStyles.scss";

.setupWrapper {
  margin-top: $spacing-2;
  display: flex;

  @include on-bp(xl) {
    margin-top: $spacing-1;
  }
}

.timezoneWrapper {
  width: 60%;
  margin-left: $spacing-2;

  @include on-bp(md) {
    width: 40%;
  }
}
