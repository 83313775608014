import { MainWrapper, ThemeWrapper, themeFlixDark } from "@flixbus/honeycomb-react";
import {
  NavigationApp,
  ErrorBoundary,
  Notification,
  Navigation,
  getThemeSetting,
  isTestEnv,
  AuthProvider,
  StateProvider,
  TranslationProvider,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./router/Router";
import Layout from "./ui/Layout";
import "normalize.css/normalize.css";

export const AppContent: React.FC = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <StateProvider fetchPolicy="cache-and-network">
          <MainWrapper full>
            <Navigation app={NavigationApp.MUS} isUsedInternal />
            <Layout>
              <Router />
            </Layout>
          </MainWrapper>
          <Notification />
        </StateProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

const App: React.FC = () => {
  // User is Cypress
  if (isTestEnv() && process.env.NODE_ENV !== "production") {
    return (
      <ThemeWrapper theme="flix" style={{ minWidth: "1200px" }}>
        <TranslationProvider usesCookies isUsedInternal>
          <AppContent />
        </TranslationProvider>
      </ThemeWrapper>
    );
  }

  return (
    <ThemeWrapper
      id="theme-wrapper"
      theme={getThemeSetting()}
      themes={{ "flix-dark": themeFlixDark }}
    >
      <TranslationProvider usesCookies isUsedInternal>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </TranslationProvider>
    </ThemeWrapper>
  );
};

export default App;
